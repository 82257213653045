import React, { useState } from "react";
import {
  HeroContainer,
  HeroBg,
  HeroImage,
  HeroContent,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  HeroH1,
} from "./HeroElements";
import { Button } from "../ButtonElement.js";
import Image from "../../images/background.png";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const scheduleConsultHandler = () => {
    console.log("button clicked");
    window.open("https://hello.hecticapp.com/01/mocreate/consultation-zoom");
  };

  return (
    <HeroContainer id="home">
      <HeroBg>
        <HeroImage src={Image} />
      </HeroBg>
      <HeroContent>
        <HeroH1>
          Delivering the digital products <pink>you need.</pink>
        </HeroH1>
        <HeroBtnWrapper>
          <Button
            onClick={scheduleConsultHandler}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
          >
            Get Free Quote {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
