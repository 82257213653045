import React from "react";
import {
  ExpertiseContainer,
  ExpertiseTile,
  ExpertiseImageWrapper,
  ItemImage,
    ItemHeader,
  ExpertiseWrapper
} from "./ExpertiseElements";
import Icon1 from "../../images/design.svg"
import Icon2 from "../../images/website.svg"
import Icon3 from "../../images/ios.svg"
import Icon4 from "../../images/android.svg"

/* Icons Used:
<a target="_blank" href="https://icons8.com/icon/79089/ios-logo">iOS Logo</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>
*/
const Expertise = () => {
  return (
    <>
      <ExpertiseContainer>
        <ExpertiseWrapper>
          <ExpertiseTile>
              <ItemImage src={Icon1} />
              <ItemHeader>Brand</ItemHeader>
          </ExpertiseTile>
          <ExpertiseTile>
              <ItemImage src={Icon2} />
              <ItemHeader>Web</ItemHeader>
          </ExpertiseTile>
          <ExpertiseTile>
              <ItemImage src={Icon3} />
              <ItemHeader>iOS</ItemHeader>
          </ExpertiseTile>
          <ExpertiseTile>
              <ItemImage src={Icon4} />
              <ItemHeader>Android</ItemHeader>
          </ExpertiseTile>
        </ExpertiseWrapper>
      </ExpertiseContainer>
    </>
  );
};

export default Expertise;
