import React from "react";
import { SideBarContainer, Icon, CloseIcon, SideBarWrapper, SideBarMenu, SideBarLink, SideBtnWrap, SideBarRoute } from "./SideBarElements";

const Sidebar = ({ toggle, isOpen }) => {
  
    
  const scheduleConsultHandler = () => {
    window.open("https://hello.hecticapp.com/01/mocreate/consultation-zoom");
  }

  return (
    <SideBarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SideBarWrapper>
        <SideBarMenu>
        <SideBarLink to='home' onClick={toggle}>Home</SideBarLink>
          <SideBarLink to='about' onClick={toggle}>About</SideBarLink>
          <SideBarLink to='services' onClick={toggle}>Services</SideBarLink>
          {/* <SideBarLink to="portfolio" onClick={toggle}>Portfolio</SideBarLink> */}
          <SideBarLink to='contact_us' onClick={toggle}>Contact Us</SideBarLink>
        </SideBarMenu> 
        <SideBtnWrap>
          <SideBarRoute onClick={scheduleConsultHandler}>Let's Talk</SideBarRoute>
        </SideBtnWrap>
      </SideBarWrapper>
    </SideBarContainer>
  );
};

export default Sidebar;
