import React from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  BookBtn,
  BookBtnLink,
  NavLogoWrapper, 
  LogoImage
} from "./NavBarElements";
import { FaBars } from "react-icons/fa";
import Logo from "../../images/logo.png";

const Navbar = ({ toggle }) => {
  const scheduleConsultHandler = () => {
    window.open("https://hello.hecticapp.com/01/mocreate/consultation-zoom");
  };

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogoWrapper>
            <NavLogo to="home"
             smooth={true}
             duration={500}
              spy={true}
            >
              <LogoImage src={Logo} />
            </NavLogo>
          </NavLogoWrapper>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to="home"
                smooth={true}
                duration={500}
                spy={true}
              >Home</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="about"
               smooth={true}
               duration={500}
                spy={true}>About
                </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="services"
               smooth={true}
               duration={500}
               spy={true}>Services</NavLinks>
            </NavItem>
            {/* <NavItem>
              <NavLinks to="portfolio">Portfolio</NavLinks>
            </NavItem> */}
            <NavItem>
              <NavLinks to="contact_us"
               smooth={true}
               duration={500}
               spy={true}>Contact</NavLinks>
            </NavItem>
          </NavMenu>
          <BookBtn>
            <BookBtnLink onClick={scheduleConsultHandler}>
              Let's Talk
            </BookBtnLink>
          </BookBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
