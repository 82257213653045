import React, { useState } from "react";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import {
  homeObjOne,
  homeObjTwo,
  homeObjThree,
  homeObjFour,
} from "../components/InfoSection/Data";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Navbar/Sidebar";
import Services from "../components/Services";
import Icon1 from "../images/online-3.svg";
import Icon2 from "../images/love_mobile.svg";
import Icon3 from "../images/stripe_payments.svg";
import Icon4 from "../images/building_website.svg";
import AboutSection from "../components/AboutSection/index.js";
import Expertise from "../components/Expertise";


const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <Expertise />
      <InfoSection {...homeObjOne} img={Icon1} /> 
      <InfoSection {...homeObjTwo} img={Icon2} />
      <InfoSection {...homeObjThree} img={Icon3} />
      <InfoSection {...homeObjFour} img={Icon4} />

      <Services />
      {/* <PortfolioSection /> */}
      <AboutSection />
      {/* <ProjectView /> */}
  
    </>
  );
};

export default Home;
