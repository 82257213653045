import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./pages";

function App() {
  return (
    <>
      <Helmet>
        <title> Mocreate | Engineering Consulting </title>
    </Helmet>
    <Router>
      <Home />
      </Router>
      </>
  );
}

export default App;
