import React, { useState } from "react";
import {
  AboutContainer,
  ContactInfoWrapper,
  ContactInfoH2,
  ContactInfoP,
  ContactTileWrapper,
  ContactTile,
  ContactInformation,
  ContactForm,
  ContactFormWrapper,
  SubmitButton,
  ConfirmationMessage
} from "./AboutElements";
import { FaPhone, FaAt, FaSearchLocation } from "react-icons/fa";
import { IoMdSend } from "react-icons/io";
import emailjs from "@emailjs/browser";

const AboutSection = () => {
  const [enteredFName, setEnteredFName] = useState("");
  const [enteredLName, setEnteredLName] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [enteredPhone, setEnteredPhone] = useState("");
  const [enteredMessage, setEnteredMessage] = useState("");
  const [messageSent, setMessageSent] = useState(false);

  const emailRegex = /\S+@\S+\.\S+/;

  const fnameHandler = (e) => {
    setEnteredFName(e.target.value);
  };

  const lnameHandler = (e) => {
    setEnteredLName(e.target.value);
  };

  const emailHandler = (e) => {
    const email = e.target.value;
    if (emailRegex.test(email) || email == "") {
      setValidEmail(true);
    }
    else {
      setValidEmail(false);
    }
    setEnteredEmail(e.target.value);
  };

  const phoneHandler = (e) => {
    setEnteredPhone(e.target.value);
  };

  const messageHandler = (e) => {
    setEnteredMessage(e.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (validEmail) {
    emailjs
      .sendForm(
        "service_2r89rfk",
        "template_0w4yolt",
        e.target,
        "Qi-JWX-dU5oZCRsXr"
      )
      .then((res) => {
        console.log(res);
        setEnteredFName("");
        setEnteredLName("");
        setEnteredEmail("");
        setEnteredPhone("");
        setEnteredMessage("");
        setMessageSent(true);
      })
      .catch((err) => console.log(err));
    }

  };
  return (
    <>
      <AboutContainer id="contact_us">
        <ContactInfoWrapper>
        <ContactInfoH2>Get in touch.</ContactInfoH2>
          <ContactInfoP>
            Don't be shy, we love to hear from our clients. To get in touch with one of our agents, fill out the form.  Typical response time is
            24 hours or less.
          </ContactInfoP>
          <ContactTileWrapper>
            <ContactTile>
              <FaPhone color="#F900BF" />
              <ContactInformation>(470) 577-8137</ContactInformation>
            </ContactTile>
            <ContactTile>
              <FaAt color="#F900BF" />
              <ContactInformation>mo@themocreate.com</ContactInformation>
            </ContactTile>
            {/* <ContactTile>
              <FaSearchLocation color="#4700D8" />
              <ContactInformation>*virtual address*</ContactInformation>
            </ContactTile> */}
          </ContactTileWrapper>
        </ContactInfoWrapper>
        <ContactFormWrapper> 
        <ContactForm onSubmit={sendEmail}>
             {/* {!validEmail && <ErrorMessage>Please enter a valid email.</ErrorMessage>} */}
            <label>First Name</label>
            <input type="text" name="fname" value={enteredFName} onChange={fnameHandler}></input>
            <label>Last Name</label>
            <input type="text" name="lname" value={enteredLName} onChange={lnameHandler} />
            <label>Email Address</label>
            <input
              type="text"
              name="user_email"
              value={enteredEmail} onChange={emailHandler}
              className={validEmail ? 'success' : 'error'}
            />
            <label>Phone Number</label>
            <input type="text" name="phone" value={enteredPhone} onChange={phoneHandler} />
            <label>Message</label>
            <textarea type="text" name="message" rows="4" className={"message"} value={enteredMessage} onChange={messageHandler} />
            {messageSent && <ConfirmationMessage>Message Sent.</ConfirmationMessage>}
            <SubmitButton type="submit">
              <IoMdSend />
              Send
            </SubmitButton>
          </ContactForm>
        </ContactFormWrapper>
      </AboutContainer>
    </>
  );
};

export default AboutSection;
