import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";
import Icon1 from "../../images/icon1.svg";
import Icon2 from "../../images/icon2.svg";
import Icon3 from "../../images/icon3.svg";
import Icon4 from "../../images/internet.svg";
const Services = () => {
  return (
    <>
      <ServicesContainer id="services">
        <ServicesH1>Our Services</ServicesH1>
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIcon src={Icon1} />
            <ServicesH2>Discovery</ServicesH2>
            <ServicesP>
              Conceptualizing a product can be difficult. We develop a stradegy
              and solution that will exceed your business needs.
            </ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={Icon2} />
            <ServicesH2>Design</ServicesH2>
            <ServicesP>
              Projects come in all shapes and sizes. We create attractive,
              simple, and easy-to-use UI/UX designs for digital products.
            </ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={Icon3} />
            <ServicesH2>Development</ServicesH2>
            <ServicesP>
              No project is too large. Our highly skilled engineers develop
              secure, scalable, and adaptable web and mobile apps.
            </ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={Icon4} />
            <ServicesH2>Deployment</ServicesH2>
            <ServicesP>
              Not tech savy? No problem. Let us take care of the web hosting and maintenance
              for your web applications once it's completed.
            </ServicesP>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default Services;
