export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Easy Process",
  headline: "Unrestricted access to your project.",
  description:
    "We aim to provide a consistent customer experience. Our portal is available to all clients. Track the status of the project, the deliverables, and more at any moment.",
  buttonLabel: "Get Started",
  imgStart: false,
  alt: "client portal",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: "Satisfaction Guranteed",
  headline: "A final product you will love.",
  description:
    "Gain access to a private staging environment to watch as we bring your idea to life. Updates will be released by our engineers as soon as they are ready.",
  buttonLabel: "Learn More",
  imgStart: true,
  alt: "client portal",
  dark: true,
  primary: true,
  darkText: true,
};

export const homeObjThree = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Secure Payments",
  headline: "Protection for you and your investment.",
  description:
    "Your security is our top priority. In our client site, Stripe is used to securely invoice and handle all payments.",
  buttonLabel: "Book Consult",
  imgStart: false,
  alt: "client portal",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjFour = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: "Fast Delivery",
  headline: "Market your product sooner than later.",
  description:
    "We share your desire to introduce the world to your product. Our engineers work quickly and efficiently to deliver your product fast.",
  buttonLabel: "Get Quote",
  imgStart: true,
  alt: "client portal",
  dark: true,
  primary: true,
  darkText: true,
};

// easy process: Hectic client portal for time tracking, important updates, and client approvals.
// satisfaction guranteed: unlimited pages and revisions, next phase must be approved
// secure payments: square cash for all payments. proper invoices provided
// fast delivery: We are eager to build our portfolio, you are eager to market your product to the world.
